import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import AsidePage from "../AsidePage";
import RolesCreate from "../RolesCreate";
import Headerpage from "../Headerpage";

function AssignRole() {
  const [CustomerData, setCustomerData] = useState(null);


  useEffect(() => {
    const fetchCustomerData = async () => {
      const customerid = localStorage.getItem("customerid");
      const token = localStorage.getItem("token");

      if (!customerid || !token) {
        console.error("Customer ID or token is missing");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/customer/${customerid}`,
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );

        setCustomerData(response.data); // Access the data directly from response.data
        // console.log(response.data);
        console.log(CustomerData);
      } catch (error) {
        console.error(
          "Error fetching customer data:",
          error.response?.statusText || error.message
        );
      }
    };

    fetchCustomerData();
  }, []); // Ensures this is called once when the component mounts

  return (
    <div className="flex min-h-screen bg-background">
      <AsidePage />

      {/* Main Content */}
      <main className="flex-1 p-6 overflow-y-auto ml-64">
       <Headerpage/>
        <div className="mb-4">
          <h1 className="text-xl font-sans mb-4 p-3 font-medium rounded ">
            Create a Role
          </h1>
        </div>

       <RolesCreate/>
      </main>
    </div>
  );
}

export default AssignRole;
