import { useState, useEffect } from "react";
import UserList from "./UserList";
import axios from "axios";
import AsidePage from "../AsidePage";
import RolesCreate from "../RolesCreate";
import Headerpage from "../Headerpage";
function Homepage() {
  const [isRolesVisible1, setRolesVisible1] = useState(false);
  const [CustomerData, setCustomerData] = useState(null);
  const [isRolesVisible, setIsRolesVisible] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  

  const toggleRolesVisibility = () => {
    setIsRolesVisible(!isRolesVisible);
  };

  const toggleDropdown = (userId) => {
    setOpenDropdownId(openDropdownId === userId ? null : userId);
  };

  const handleEditClick = (user, role) => {
    setEditingUser({ ...user, role });
    setOpenDropdownId(null);
    setIsEditModalOpen(true);
  };

  const handleUpdateUser = (updatedUser) => {
    // Implement update user logic
    setIsEditModalOpen(false);
  };

  
  // useEffect(() => {
  //   const fetchCustomerData = async () => {
  //     const customerid = localStorage.getItem("customerid");
  //     const token = localStorage.getItem("token");

  //     if (!customerid || !token) {
  //       console.error("Customer ID or token is missing");
  //       return;
  //     }

  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_BASE_URL}/api/customer/${customerid}`,
  //         {
  //           headers: {
  //             "x-auth-token": token,
  //           },
  //         }
  //       );

  //       setCustomerData(response.data); // Access the data directly from response.data
  //       // console.log(response.data);
  //       console.log(CustomerData);
  //     } catch (error) {
  //       console.error(
  //         "Error fetching customer data:",
  //         error.response?.statusText || error.message
  //       );
  //     }
  //   };

  //   fetchCustomerData();
  // }, []);

  const toggleRolesVisibility1 = () => {
    setRolesVisible1((prevState) => !prevState);
  };


  return (
    <div className="flex min-h-screen bg-background">
      {/* Sidebar */}
      <AsidePage />

      {/* Main Content */}
      <main className="flex-1 p-6 overflow-y-auto ml-64">
        <Headerpage/>
        <div className="py-4">
          <button
            className="mb-4 p-3 text-white font-medium rounded bg-primary"
            onClick={toggleRolesVisibility1}
          >
            Create a Role
          </button>
        </div>

        {isRolesVisible1 && (
          <RolesCreate/>
        )}
        <div className="relative">
        <UserList
            openDropdownId={openDropdownId}
            toggleDropdown={toggleDropdown}
            handleEditClick={handleEditClick}
          />
      </div>
      </main>
    </div>
  );
}

export default Homepage;
