import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddressSelector from "./AddressSelector";
import { useState, useEffect } from "react";
import axios from "axios";
import AddAddressPage from "./AddAddressPage";
import AsidePage from "../AsidePage";
import Headerpage from "../Headerpage";
export default function AssignRoles() {
  const { role } = useParams();
  const lowerCaseRole = role.toLowerCase();
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedAddressIds, setSelectedAddressIds] = useState([]);
  const [CustomerData, setCustomerData] = useState();
  const navigate = useNavigate();

  const username = localStorage.getItem("username");
  const userrole = localStorage.getItem("userrole");
  const handleAddressesSelected = (ids) => {
    console.log("Selected Address IDs in Parent:", ids);
    setSelectedAddressIds(ids); // Store selected address IDs in state
  };
  const roleDisplay = lowerCaseRole === 'admin+' ? 'adminpro' : lowerCaseRole;
  console.log("ll",roleDisplay)


  const handleAssignRole = async () => {
    const customerid = localStorage.getItem("customerid");
    const roleData = {
      name: name,
      email: email,
      phone: phone,
      role: roleDisplay,
      customer_id: customerid,
      addressids: selectedAddressIds,
    };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/register`,
        roleData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
  
      setShowPopup(true);
      
      // Wait for a short delay before navigating
      setTimeout(() => {
        setShowPopup(false);
        navigate("/Userpage");
      }, 2000); // Adjust the delay (in milliseconds) as needed
  
    } catch (error) {
      alert(
        "Error assigning role: " +
        (error.response?.statusText || error.message)
      );
    }
  };
  useEffect(() => {
    const fetchCustomerData = async () => {
      const customerid = localStorage.getItem("customerid");
      const token = localStorage.getItem("token");

      if (!customerid || !token) {
        console.error("Customer ID or token is missing");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/customer/${customerid}`,
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );

        setCustomerData(response.data); // Access the data directly from response.data
        console.log(response.data);
      } catch (error) {
        console.error(
          "Error fetching customer data:",
          error.response?.statusText || error.message
        );
      }
    };

    fetchCustomerData();
  }, []);

  const handleClosePopup = () => {
    // Close the popup
    setShowPopup(false);
  };
  return (
    <div className="flex min-h-screen bg-background">
     <AsidePage/>
     <main className="flex-1 p-6 overflow-y-auto ml-64">
       <Headerpage/>
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-xl font-sans py-3 font-medium rounded">
            Create a {role === 'SuperAdmin' ? 'Superadmin' : (role === 'adminpro' ? 'Admin+' : role)}
            </h1>
            <button
              className="bg-primary text-white py-2 px-4 rounded"
              onClick={handleAssignRole}
            >
              Assign Role
            </button>
          </div>
          <div className="sm:flex flex-wrap justify-between gap-4 mb-4">
            <div className="flex flex-col w-full sm:w-[46%] mb-4 sm:mb-0">
              <label className="text-lg font-normal mb-2">Name:</label>
              <input
                type="text"
                placeholder="Enter name"
                className="w-full p-3 border rounded-lg bg-background outline-primary placeholder:font-extralight font-extralight text-sm  appearance-none border-borderborder"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="w-full sm:w-[46%]">
              <label className="text-lg font-normal mb-2">Email:</label>
              <input
                type="text"
                placeholder="Enter email"
                className="mt-2 w-full p-3 border rounded-lg bg-background outline-primary placeholder:font-extralight font-extralight text-sm  appearance-none border-borderborder"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full sm:w-[46%]">
          <label className="text-lg font-normal">Phone:</label>
          <input
            type="text"
            className="w-full p-3 rounded-lg bg-background mt-2 outline-primary border border-borderborder"
            pattern="\d{10}"
            title="Please enter a valid 10-digit phone number"
            onChange={(e) => {
              const phone = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
              if (phone.length <= 10) {
                setPhone(phone);
              }
            }}
            value={phone}
          />
          
          </div>
        </div>

        <AddAddressPage onAddressesSelected={handleAddressesSelected} />
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
            <div className="bg-white p-6 rounded-lg text-center flex flex-col items-center justify-center h-1/2 w-1/5">
              <svg
                width="180"
                height="166"
                viewBox="0 0 180 166"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_970_4517)">
                  <rect
                    x="16.5"
                    y="12"
                    width="134"
                    height="134"
                    rx="67"
                    fill="#FEF9F5"
                    fill-opacity="0.44"
                    shape-rendering="crispEdges"
                  />
                  <rect
                    x="17"
                    y="12.5"
                    width="133"
                    height="133"
                    rx="66.5"
                    stroke="#D0F0C0"
                    stroke-opacity="0.11"
                    shape-rendering="crispEdges"
                  />
                  <rect
                    x="28"
                    y="22.5"
                    width="112"
                    height="112"
                    rx="56"
                    stroke="#BDE0ED"
                    stroke-opacity="0.15"
                  />
                  <rect
                    x="36.5"
                    y="32"
                    width="94"
                    height="94"
                    rx="47"
                    fill="#3CABD4"
                  />
                  <path
                    d="M75.5859 96L59.5 79.8836L63.5215 75.8545L75.5859 87.9418L101.479 62L105.5 66.0291L75.5859 96Z"
                    fill="white"
                  />
                  <path
                    d="M138.144 89.9619C138.17 89.8895 138.218 89.827 138.281 89.7828C138.344 89.7386 138.419 89.7148 138.496 89.7148C138.574 89.7148 138.649 89.7386 138.712 89.7828C138.775 89.827 138.823 89.8895 138.849 89.9619L139.207 90.9414C139.489 91.7093 139.934 92.4066 140.512 92.9848C141.09 93.563 141.788 94.0082 142.555 94.2894L143.534 94.6479C143.607 94.6742 143.669 94.7221 143.713 94.7852C143.758 94.8482 143.781 94.9234 143.781 95.0004C143.781 95.0774 143.758 95.1526 143.713 95.2156C143.669 95.2787 143.607 95.3266 143.534 95.3529L142.555 95.7114C141.788 95.9926 141.09 96.4378 140.512 97.016C139.934 97.5942 139.489 98.2915 139.207 99.0594L138.849 100.038C138.823 100.111 138.775 100.173 138.712 100.217C138.649 100.262 138.574 100.285 138.496 100.285C138.419 100.285 138.344 100.262 138.281 100.217C138.218 100.173 138.17 100.111 138.144 100.038L137.785 99.0594C137.504 98.2915 137.059 97.5942 136.481 97.016C135.903 96.4378 135.205 95.9926 134.437 95.7114L133.458 95.3529C133.386 95.3266 133.323 95.2787 133.279 95.2156C133.235 95.1526 133.211 95.0774 133.211 95.0004C133.211 94.9234 133.235 94.8482 133.279 94.7852C133.323 94.7221 133.386 94.6742 133.458 94.6479L134.437 94.2894C135.205 94.0082 135.903 93.563 136.481 92.9848C137.059 92.4066 137.504 91.7093 137.785 90.9414L138.144 89.9619Z"
                    fill="#D0F0C0"
                  />
                  <path
                    d="M138.144 125.962C138.17 125.89 138.218 125.827 138.281 125.783C138.344 125.739 138.419 125.715 138.496 125.715C138.574 125.715 138.649 125.739 138.712 125.783C138.775 125.827 138.823 125.89 138.849 125.962L139.207 126.941C139.489 127.709 139.934 128.407 140.512 128.985C141.09 129.563 141.788 130.008 142.555 130.289L143.534 130.648C143.607 130.674 143.669 130.722 143.713 130.785C143.758 130.848 143.781 130.923 143.781 131C143.781 131.077 143.758 131.153 143.713 131.216C143.669 131.279 143.607 131.327 143.534 131.353L142.555 131.711C141.788 131.993 141.09 132.438 140.512 133.016C139.934 133.594 139.489 134.292 139.207 135.059L138.849 136.038C138.823 136.111 138.775 136.173 138.712 136.217C138.649 136.262 138.574 136.285 138.496 136.285C138.419 136.285 138.344 136.262 138.281 136.217C138.218 136.173 138.17 136.111 138.144 136.038L137.785 135.059C137.504 134.292 137.059 133.594 136.481 133.016C135.903 132.438 135.205 131.993 134.437 131.711L133.458 131.353C133.386 131.327 133.323 131.279 133.279 131.216C133.235 131.153 133.211 131.077 133.211 131C133.211 130.923 133.235 130.848 133.279 130.785C133.323 130.722 133.386 130.674 133.458 130.648L134.437 130.289C135.205 130.008 135.903 129.563 136.481 128.985C137.059 128.407 137.504 127.709 137.785 126.941L138.144 125.962Z"
                    fill="#D0F0C0"
                  />
                  <path
                    d="M36.6782 94.8817C36.7023 94.8154 36.7462 94.758 36.8041 94.7175C36.8619 94.677 36.9307 94.6553 37.0013 94.6553C37.0719 94.6553 37.1408 94.677 37.1986 94.7175C37.2565 94.758 37.3004 94.8154 37.3245 94.8817L37.6531 95.7796C37.9108 96.4835 38.3189 97.1227 38.849 97.6528C39.379 98.1828 40.0182 98.5909 40.7221 98.8486L41.6193 99.1772C41.6856 99.2013 41.743 99.2453 41.7835 99.3031C41.824 99.3609 41.8458 99.4298 41.8458 99.5004C41.8458 99.571 41.824 99.6399 41.7835 99.6977C41.743 99.7555 41.6856 99.7994 41.6193 99.8235L40.7221 100.152C40.0182 100.41 39.379 100.818 38.849 101.348C38.3189 101.878 37.9108 102.517 37.6531 103.221L37.3245 104.118C37.3004 104.185 37.2565 104.242 37.1986 104.283C37.1408 104.323 37.0719 104.345 37.0013 104.345C36.9307 104.345 36.8619 104.323 36.8041 104.283C36.7462 104.242 36.7023 104.185 36.6782 104.118L36.3496 103.221C36.0919 102.517 35.6838 101.878 35.1537 101.348C34.6237 100.818 33.9845 100.41 33.2806 100.152L32.3827 99.8235C32.3164 99.7994 32.259 99.7555 32.2185 99.6977C32.178 99.6399 32.1562 99.571 32.1562 99.5004C32.1562 99.4298 32.178 99.3609 32.2185 99.3031C32.259 99.2453 32.3164 99.2013 32.3827 99.1772L33.2806 98.8486C33.9845 98.5909 34.6237 98.1828 35.1537 97.6528C35.6838 97.1227 36.0919 96.4835 36.3496 95.7796L36.6782 94.8817Z"
                    fill="#BDE0ED"
                  />
                  <path
                    d="M32.9093 14.6022C32.9531 14.4815 33.0329 14.3773 33.1381 14.3036C33.2432 14.23 33.3684 14.1904 33.4968 14.1904C33.6251 14.1904 33.7504 14.23 33.8555 14.3036C33.9606 14.3773 34.0405 14.4815 34.0843 14.6022L34.6818 16.2347C35.1504 17.5145 35.8924 18.6767 36.8561 19.6404C37.8198 20.6041 38.982 21.3461 40.2618 21.8147L41.893 22.4122C42.0137 22.456 42.1179 22.5359 42.1916 22.641C42.2653 22.7461 42.3048 22.8713 42.3048 22.9997C42.3048 23.1281 42.2653 23.2533 42.1916 23.3584C42.1179 23.4635 42.0137 23.5434 41.893 23.5872L40.2618 24.1847C38.982 24.6533 37.8198 25.3953 36.8561 26.359C35.8924 27.3227 35.1504 28.4849 34.6818 29.7647L34.0843 31.3959C34.0405 31.5166 33.9606 31.6209 33.8555 31.6945C33.7504 31.7682 33.6251 31.8077 33.4968 31.8077C33.3684 31.8077 33.2432 31.7682 33.1381 31.6945C33.0329 31.6209 32.9531 31.5166 32.9093 31.3959L32.3118 29.7647C31.8432 28.4849 31.1012 27.3227 30.1375 26.359C29.1738 25.3953 28.0115 24.6533 26.7318 24.1847L25.0993 23.5872C24.9786 23.5434 24.8744 23.4635 24.8007 23.3584C24.727 23.2533 24.6875 23.1281 24.6875 22.9997C24.6875 22.8713 24.727 22.7461 24.8007 22.641C24.8744 22.5359 24.9786 22.456 25.0993 22.4122L26.7318 21.8147C28.0115 21.3461 29.1738 20.6041 30.1375 19.6404C31.1012 18.6767 31.8432 17.5145 32.3118 16.2347L32.9093 14.6022Z"
                    fill="#BDE0ED"
                  />
                  <path
                    d="M147.091 37.1222C147.122 37.0378 147.178 36.9648 147.251 36.9132C147.325 36.8616 147.413 36.834 147.502 36.834C147.592 36.834 147.68 36.8616 147.754 36.9132C147.827 36.9648 147.883 37.0378 147.914 37.1222L148.332 38.265C148.66 39.1608 149.179 39.9744 149.854 40.649C150.529 41.3236 151.342 41.843 152.238 42.171L153.38 42.5892C153.464 42.6199 153.537 42.6758 153.589 42.7494C153.64 42.8229 153.668 42.9106 153.668 43.0005C153.668 43.0903 153.64 43.178 153.589 43.2516C153.537 43.3251 153.464 43.3811 153.38 43.4117L152.238 43.83C151.342 44.158 150.529 44.6774 149.854 45.352C149.179 46.0266 148.66 46.8401 148.332 47.736L147.914 48.8778C147.883 48.9623 147.827 49.0353 147.754 49.0868C147.68 49.1384 147.592 49.1661 147.502 49.1661C147.413 49.1661 147.325 49.1384 147.251 49.0868C147.178 49.0353 147.122 48.9623 147.091 48.8778L146.673 47.736C146.345 46.8401 145.826 46.0266 145.151 45.352C144.476 44.6774 143.663 44.158 142.767 43.83L141.624 43.4117C141.54 43.3811 141.467 43.3251 141.415 43.2516C141.364 43.178 141.336 43.0903 141.336 43.0005C141.336 42.9106 141.364 42.8229 141.415 42.7494C141.467 42.6758 141.54 42.6199 141.624 42.5892L142.767 42.171C143.663 41.843 144.476 41.3236 145.151 40.649C145.826 39.9744 146.345 39.1608 146.673 38.265L147.091 37.1222Z"
                    fill="#D0F0C0"
                  />
                  <path
                    d="M23.5569 36.2014C23.5898 36.1109 23.6497 36.0327 23.7285 35.9775C23.8074 35.9222 23.9013 35.8926 23.9976 35.8926C24.0938 35.8926 24.1878 35.9222 24.2666 35.9775C24.3454 36.0327 24.4054 36.1109 24.4382 36.2014L24.8863 37.4258C25.2378 38.3856 25.7943 39.2573 26.517 39.9801C27.2398 40.7028 28.1115 41.2593 29.0713 41.6108L30.2948 42.0589C30.3853 42.0917 30.4634 42.1517 30.5187 42.2305C30.5739 42.3093 30.6036 42.4033 30.6036 42.4995C30.6036 42.5958 30.5739 42.6897 30.5187 42.7686C30.4634 42.8474 30.3853 42.9073 30.2948 42.9402L29.0713 43.3883C28.1115 43.7397 27.2398 44.2962 26.517 45.019C25.7943 45.7418 25.2378 46.6134 24.8863 47.5733L24.4382 48.7967C24.4054 48.8872 24.3454 48.9654 24.2666 49.0206C24.1878 49.0759 24.0938 49.1055 23.9976 49.1055C23.9013 49.1055 23.8074 49.0759 23.7285 49.0206C23.6497 48.9654 23.5898 48.8872 23.5569 48.7967L23.1088 47.5733C22.7574 46.6134 22.2009 45.7418 21.4781 45.019C20.7553 44.2962 19.8837 43.7397 18.9238 43.3883L17.6994 42.9402C17.609 42.9073 17.5308 42.8474 17.4755 42.7686C17.4203 42.6897 17.3906 42.5958 17.3906 42.4995C17.3906 42.4033 17.4203 42.3093 17.4755 42.2305C17.5308 42.1517 17.609 42.0917 17.6994 42.0589L18.9238 41.6108C19.8837 41.2593 20.7553 40.7028 21.4781 39.9801C22.2009 39.2573 22.7574 38.3856 23.1088 37.4258L23.5569 36.2014Z"
                    fill="#D0F0C0"
                  />
                  <path
                    d="M155.557 46.2014C155.59 46.1109 155.65 46.0327 155.729 45.9775C155.807 45.9222 155.901 45.8926 155.998 45.8926C156.094 45.8926 156.188 45.9222 156.267 45.9775C156.345 46.0327 156.405 46.1109 156.438 46.2014L156.886 47.4258C157.238 48.3856 157.794 49.2573 158.517 49.9801C159.24 50.7028 160.111 51.2593 161.071 51.6108L162.295 52.0589C162.385 52.0917 162.463 52.1517 162.519 52.2305C162.574 52.3093 162.604 52.4033 162.604 52.4995C162.604 52.5958 162.574 52.6897 162.519 52.7686C162.463 52.8474 162.385 52.9073 162.295 52.9402L161.071 53.3883C160.111 53.7397 159.24 54.2962 158.517 55.019C157.794 55.7418 157.238 56.6134 156.886 57.5733L156.438 58.7967C156.405 58.8872 156.345 58.9654 156.267 59.0206C156.188 59.0759 156.094 59.1055 155.998 59.1055C155.901 59.1055 155.807 59.0759 155.729 59.0206C155.65 58.9654 155.59 58.8872 155.557 58.7967L155.109 57.5733C154.757 56.6134 154.201 55.7418 153.478 55.019C152.755 54.2962 151.884 53.7397 150.924 53.3883L149.699 52.9402C149.609 52.9073 149.531 52.8474 149.476 52.7686C149.42 52.6897 149.391 52.5958 149.391 52.4995C149.391 52.4033 149.42 52.3093 149.476 52.2305C149.531 52.1517 149.609 52.0917 149.699 52.0589L150.924 51.6108C151.884 51.2593 152.755 50.7028 153.478 49.9801C154.201 49.2573 154.757 48.3856 155.109 47.4258L155.557 46.2014Z"
                    fill="#BDE0ED"
                  />
                  <path
                    d="M128.909 109.602C128.953 109.482 129.033 109.377 129.138 109.304C129.243 109.23 129.368 109.19 129.497 109.19C129.625 109.19 129.75 109.23 129.855 109.304C129.961 109.377 130.04 109.482 130.084 109.602L130.682 111.235C131.15 112.514 131.892 113.677 132.856 114.64C133.82 115.604 134.982 116.346 136.262 116.815L137.893 117.412C138.014 117.456 138.118 117.536 138.192 117.641C138.265 117.746 138.305 117.871 138.305 118C138.305 118.128 138.265 118.253 138.192 118.358C138.118 118.464 138.014 118.543 137.893 118.587L136.262 119.185C134.982 119.653 133.82 120.395 132.856 121.359C131.892 122.323 131.15 123.485 130.682 124.765L130.084 126.396C130.04 126.517 129.961 126.621 129.855 126.695C129.75 126.768 129.625 126.808 129.497 126.808C129.368 126.808 129.243 126.768 129.138 126.695C129.033 126.621 128.953 126.517 128.909 126.396L128.312 124.765C127.843 123.485 127.101 122.323 126.137 121.359C125.174 120.395 124.012 119.653 122.732 119.185L121.099 118.587C120.979 118.543 120.874 118.464 120.801 118.358C120.727 118.253 120.688 118.128 120.688 118C120.688 117.871 120.727 117.746 120.801 117.641C120.874 117.536 120.979 117.456 121.099 117.412L122.732 116.815C124.012 116.346 125.174 115.604 126.137 114.64C127.101 113.677 127.843 112.514 128.312 111.235L128.909 109.602Z"
                    fill="#BDE0ED"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_970_4517"
                    x="0.6"
                    y="0.1"
                    width="178.8"
                    height="165.8"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="7.95" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.846667 0 0 0 0 0.846667 0 0 0 0 0.846667 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_970_4517"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_970_4517"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>

              <h2 className=" mt-5 mb-20 font-medium">
                Role Created Successfully!
              </h2>
              <button
                className="mb-4 bg-primary text-white py-3 px-10 rounded"
                onClick={handleClosePopup}
              >
                Done
              </button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
