import React from 'react';
import { X } from 'lucide-react';
import { useState } from 'react';

const SchedulePopup = ({handleScheduleclose}) => {
  const [startTime, setStartTime] = useState('0:00');
  const [startPeriod, setStartPeriod] = useState('PM');
  const [endTime, setEndTime] = useState('0:0');
  const [endPeriod, setEndPeriod] = useState('PM');
  const [frequency, setFrequency] = useState('custom');
  const [customDays, setCustomDays] = useState({
    S: false, M: false, T: true, W: true, T: false, F: false, S: false
  });

  const handleTimeChange = (setter) => (e) => {
    let value = e.target.value.replace(/[^0-9]/g, '');
    
    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    if (value.length > 2) {
      const hours = parseInt(value.slice(0, 2));
      const minutes = parseInt(value.slice(2));
      
      const validHours = Math.min(12, Math.max(1, hours || 0));
      const validMinutes = Math.min(59, Math.max(0, minutes || 0));

      value = `${validHours.toString().padStart(2, '0')}:${validMinutes.toString().padStart(2, '0')}`;
    } else if (value.length > 0) {
      const hours = parseInt(value);
      const validHours = Math.min(12, Math.max(1, hours || 0));
      value = `${validHours.toString().padStart(2, '0')}:`;
    }

    setter(value);
  };

  const handlePeriodChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleFrequencyChange = (value) => {
    setFrequency(value);
  };

  const handleCustomDayToggle = (day) => {
    setCustomDays(prev => ({ ...prev, [day]: !prev[day] }));
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center backdrop-blur-sm">
      <div className="bg-white rounded-lg p-6 w-1/3 max-w-md">
        <div className="flex justify-between items-center mb-6">
          <p className="text-lg font-medium font-base">Set a timer</p>
        </div>
        
        <div className="mb-4">
        <div className="mb-4 flex justify-between items-center">
          <div className="flex items-center">
            <input
              type="text"
              value={startTime}
              onChange={handleTimeChange(setStartTime)}
              placeholder="--:--"
              className="w-16 text-center border rounded p-1"
            />
            <select 
              value={startPeriod}
              onChange={handlePeriodChange(setStartPeriod)}
              className="ml-1 border rounded p-1"
            >
              <option>PM</option>
              <option>AM</option>
            </select>
          </div>
          <div className="flex items-center">
            <input
              type="text"
              value={endTime}
              onChange={handleTimeChange(setEndTime)}
              placeholder="--:--"
              className="w-16 text-center border rounded p-1"
            />
            <select 
              value={endPeriod}
              onChange={handlePeriodChange(setEndPeriod)}
              className="ml-1 border rounded p-1"
            >
              <option>PM</option>
              <option>AM</option>
            </select>
          </div>
        </div>
          
          <p className="font-medium text-base mt-6 mb-4">Frequency</p>
          <div className="mb-2">
          {["Once", "Daily", "Monday - Friday", "Custom"].map((option) => (
            <div key={option} className="flex items-center justify-between mb-2">
              <span className="text-sm">{option}</span>
              <input
                type="radio"
                className={`form-radio w-5 h-5 ${frequency === option.toLowerCase() ? 'bg-primary' : 'border-gray-50'}`} // Adjust color here
                style={{ accentColor: frequency === option.toLowerCase() ? '#A14996' : '#A14996' }} // Inline styling for modern browsers className="form-radio focus:ring-red-500 w-5 h-5" // Increased size here
                value={option.toLowerCase()}
                checked={frequency === option.toLowerCase()}
                onChange={() => handleFrequencyChange(option.toLowerCase())}
              />
            </div>
          ))}
        </div>
          
        <div className="flex justify-between mb-6">
            {["S", "M", "T", "W", "T", "F", "S"].map((day) => (
              <button
                key={day}
                className={`w-8 h-8 text-sm ${customDays[day] ? "bg-selfcleancolorpop text-primary" : "bg-white"
                  }`}
                onClick={() => handleCustomDayToggle(day)}
              >
                {day}
              </button>
            ))}
          </div>
        </div>
        
        <p className="font-medium mb-4">Schedule</p>
        <div className="space-y-2 mb-6">
          {[1, 2].map((_, index) => (
            <div key={index} className="border border-schedulebordertime flex justify-between items-center p-3 rounded">
              <span>7:30 - 12:30 PM</span>
              <span className="text-gray-500 text-sm">Mon-Fri</span>
            </div>
          ))}
        </div>
        
        <div className="flex justify-between">
          <button className="px-4 py-2 border rounded text-primary border-primary w-[45%]" onClick={handleScheduleclose}>
            Cancel
          </button>
          <button className="px-4 py-2 bg-primary text-white rounded w-[45%]">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SchedulePopup;