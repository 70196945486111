import React, { useState,useEffect } from "react";
import axios from "axios"
import { X } from 'lucide-react';

const SelfCleanPopup = ({ show, deviceId, onClose }) => {
  const [frequency, setFrequency] = useState("once");
  const [selfClean, setSelfClean] = useState(false);
  const [customDays, setCustomDays] = useState({
    S: false,
    M: false,
    T: true,
    W: true,
    T: false,
    F: false,
    S: false,
  });

  // const handleTimeChange = (field, value) => {
  //   setTime({ ...time, [field]: value });
  // };

  const handleFrequencyChange = (value) => {
    setFrequency("once");
  };

  const handleCustomDayToggle = (day) => {
    setCustomDays({ ...customDays, [day]: !customDays[day] });
  };
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return {
      hour: formattedHours,
      minute: formattedMinutes,
      period: ampm
    };
  };

  const time = formatTime(currentTime);
  const handleonSave = async () => {
    const initialCommand = selfClean ? '!clnoff' : '!clnon';
    
    try {
      // Send the initial command
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/customers/b2blogin/sendcommand`, {
        devices: [deviceId],
        command: initialCommand
      });
      
      // If the initial command was !clnoff (stopping self-clean), send the additional !on command
      if (initialCommand === '!clnoff') {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/customers/b2blogin/sendcommand`, {
          devices: [deviceId],
          command: '!on'
        });
      }
      
      setSelfClean(prevState => !prevState);
      
      console.log(`Self Clean ${selfClean ? 'turned off' : 'initiated'} for device: ${deviceId}`);
      if (initialCommand === '!clnoff') {
        console.log('Additional "on" command sent');
      }
  
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
    <div className="bg-white p-6 rounded-lg w-1/4 mx-auto shadow-lg relative">
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        onClick={onClose}
      >
        &times;
      </button>
      <h2 className="text-lg font-medium mb-4 ">Self clean</h2>

      <div className="bg-white p-4 rounded-md mb-6">
        <div className="flex items-center justify-center text-4xl mb-2 space-x-2">
          <input
            type="number"
            value={time.hour}
            min="1"
            max="12"
            className="w-16 h-16 text-center text-3xl border rounded-lg"
            readOnly
          />
          <span className="text-4xl">:</span>
          <input
            type="number"
            value={time.minute}
            min="0"
            max="59"
            className="w-16 h-16 text-center text-3xl border rounded-lg"
            readOnly
          />
          <div className="flex flex-col justify-center ml-4">
              {["AM", "PM"].map((period, index) => (
                <button
                  key={period}
                  className={`px-4 py-1 text-sm ${
                    time.period === period
                      ? "bg-primary text-white"
                      : "bg-selfcleantogglebutton"
                  } rounded-md ${index === 0 ? "rounded-b-sm" : ""} 
                  ${index === 1 ? "rounded-t-sm" : ""} border-transparent`}
                >
                  {period}
                </button>
              ))}
            </div>
        </div>
        <div className="flex justify-center text-xs text-gray-600">
          <span className="mr-16">Hour</span>
          <span className="mr-20">Minute</span>
        </div>
      </div>

        <div className="mb-6">
          <h3 className="text-sm font-medium mb-2">Frequency</h3>
          {["Once"].map((option) => (
            <div key={option} className="flex items-center justify-between mb-2">
              <span className="text-sm">{option}</span>
              <input
                type="radio"
                className={`form-radio w-5 h-5 ${frequency === option.toLowerCase() ? 'bg-primary' : 'border-gray-50'}`} // Adjust color here
                style={{ accentColor: frequency === option.toLowerCase() ? '#A14996' : '#A14996' }} // Inline styling for modern browsers className="form-radio focus:ring-red-500 w-5 h-5" // Increased size here
                value={option.toLowerCase()}
                checked={frequency === option.toLowerCase()}
                onChange={() => handleFrequencyChange(option.toLowerCase())}
              />
            </div>
          ))}
        </div>

        {frequency === "custom" && (
          <div className="flex justify-between mb-6">
            {["S", "M", "T", "W", "T", "F", "S"].map((day) => (
              <button
                key={day}
                className={`w-8 h-8 text-sm ${customDays[day] ? "bg-selfcleancolorpop text-primary" : "bg-white"
                  }`}
                onClick={() => handleCustomDayToggle(day)}
              >
                {day}
              </button>
            ))}
          </div>
        )}

        <button
          className="w-full bg-primary text-white py-2 rounded-md text-base"
          onClick={handleonSave}
        >
          {selfClean ? 'Stop' : 'Save'}
        </button>

      </div>
    </div>
  );
};

export default SelfCleanPopup;
