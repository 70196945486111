import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import axios from "axios"
const Login1 = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission if this is within a form

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/${email}`, // URL should be a string
        
      );
   console.log(response.data)

      const { token, customerid, userrole, username, userid } = response.data; // Access response data directly
      // Store the token and customerId in localStorage
      console.log(response.data,"lll")
      localStorage.setItem('token', token);
      localStorage.setItem('customerid', customerid);
      localStorage.setItem('userrole', userrole);
      localStorage.setItem('username', username);
      localStorage.setItem('userid', userid);

      // Redirect to AssignRole page
      navigate('/Homepage'); // Adjust the route as per your app's routing configuration
    } catch (error) {
      console.error('Error during login:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div className='bg-secondary min-h-screen flex items-center justify-center'>
      <div className='bg-white p-8 rounded-lg shadow-lg max-w-md w-full sm:w-96'>
        <h1 className='text-3xl text-center mb-8'>Sign In</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type='string'
              id='email'
              name='email'
              value={email}
              onChange={handleEmailChange}
              className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
              placeholder='Enter your Email or Mobile'
              required
            />
          </div>
          <div className='flex justify-center mt-6'>
            <button
              type='submit'
              className='py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login1;
