import React, { useState, useEffect } from 'react';
import addressData from './addressData.json';

const AddressSelector = () => {
  const [selectedAddress, setSelectedAddress] = useState(addressData.join(', ')); // Initialize with all addresses selected
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAddresses, setFilteredAddresses] = useState(addressData); // Initially display all addresses

  useEffect(() => {
    // Filter addresses based on the search term
    setFilteredAddresses(addressData.filter(address =>
      address.toLowerCase().includes(searchTerm.toLowerCase())
    ));
  }, [searchTerm]);

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
    setSearchTerm(''); // Clear search input after selecting an address
  };

  return (
    <div>
      <h3 className="font-medium mb-2">Add address</h3>
      <div className="relative mb-4"> {/* Adjust margin-bottom for spacing */}
        <input
          type="text"
          placeholder="Search location"
          className="w-full border px-6 py-3 rounded-full pr-20"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && (
          <ul className="absolute z-10 bg-white border rounded w-full max-h-60 overflow-y-auto mt-1">
            {filteredAddresses.map((address, index) => (
              <li
                key={index}
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleAddressSelect(address)}
              >
                {address}
              </li>
            ))}
          </ul>
        )}
      </div>

      {selectedAddress && (
        <div className="mt-4 p-2 w-full bg-gray-100 rounded"> {/* Adjusted margin-top for spacing */}
          <p className="font-semibold">Selected Address:</p>
          <p>{selectedAddress}</p>
        </div>
      )}
    </div>
  );
};

export default AddressSelector;
