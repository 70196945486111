import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

const UserList = () => {
  const [UserList, setUserList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [popupPosition, setPopupPosition] = useState({ bottom: 0, left: 0 });
  const changeRoleButtonRefs = useRef({});
  const tableRef = useRef(null);
  const dropdownRef = useRef(null)
  const [showPopup, setShowPopup] = useState(false);

  const roleIcons = {
    Superadmin: (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="
      mr-1">
        <path d="M15.9516 10.9593L18.7653 19.2464C18.4267 19.245 18.1154 19.1818 17.7861 19.1149C17.6576 19.0888 17.5264 19.0621 17.3899 19.0384C17.0884 18.986 16.7642 18.9518 16.4234 19.0072C16.2657 19.0328 16.1087 19.0769 15.9516 19.1433V10.9593Z" stroke="#0E0E0F" stroke-width="0.7" />
        <path d="M17.0399 1.73545L17.0399 1.73465C17.0659 1.37397 17.3771 1.10182 17.7354 1.12726C18.0812 1.15256 18.3584 1.47035 18.3324 1.83287C18.0178 6.11528 16.3577 7.97607 15.5025 8.65745L15.3095 8.81122L15.3603 9.0527C15.3715 9.10565 15.3725 9.15001 15.3725 9.2459V16.2711V17.5035V23.9931C15.3725 24.4836 14.9754 24.8775 14.5037 24.8775C14.0321 24.8775 13.6349 24.4836 13.6349 23.9931V17.5035V17.1035H13.2349H12.1533H11.7533V17.5035V23.9931C11.7533 24.4836 11.3562 24.8775 10.8845 24.8775C10.4129 24.8775 10.0157 24.4836 10.0157 23.9931V17.5035V16.2711V10.6031V9.50711L9.30978 10.3454C8.57709 11.2155 7.91986 12.6407 7.80189 14.971C7.78195 15.3331 7.47626 15.6099 7.12035 15.5909C6.76348 15.5718 6.49063 15.2708 6.51039 14.8987L6.51045 14.8974C6.67214 11.6536 7.81288 9.89553 8.82866 8.97558L8.82869 8.97561L8.83297 8.97161C9.33504 8.50334 9.82398 8.23129 10.1811 8.08085C10.2193 8.06492 10.2584 8.04957 10.2982 8.0353C10.8419 8.68843 11.6613 9.1103 12.5797 9.1103C13.9419 9.1103 15.0735 8.1876 15.4254 6.93803C16.0781 6.09575 16.8329 4.52922 17.0399 1.73545Z" stroke="#0E0E0F" stroke-width="0.8" />
        <path d="M14.4735 6.12103C14.4735 7.18356 13.6136 8.04343 12.5719 8.04343C11.5302 8.04343 10.6703 7.18356 10.6703 6.12103C10.6703 5.05851 11.5302 4.19863 12.5719 4.19863C13.6183 4.19863 14.4735 5.05798 14.4735 6.12103Z" stroke="#0E0E0F" stroke-width="0.7" />
      </svg>

    ),
    "Admin+": (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
        <path d="M11.4154 13.411C14.5314 13.411 17.0574 10.8849 17.0574 7.76895C17.0574 4.65296 14.5314 2.12695 11.4154 2.12695C8.29945 2.12695 5.77344 4.65296 5.77344 7.76895C5.77344 10.8849 8.29945 13.411 11.4154 13.411Z" stroke="#0E0E0F" stroke-width="0.8" stroke-miterlimit="10" />
        <path d="M20.9253 20.1087C20.8161 20.2335 20.7017 20.3583 20.5873 20.4779C18.2785 22.8907 15.0233 24.3935 11.4197 24.3935C7.81606 24.3935 4.56086 22.8907 2.25206 20.4779C2.13766 20.3583 2.02326 20.2335 1.91406 20.1087C1.91406 17.0927 3.26606 13.8947 7.21286 13.1719C8.48166 14.1183 9.66206 14.5135 11.4249 14.5135C13.1877 14.5135 14.5241 14.1079 15.6213 13.1875C19.7085 13.9727 20.9253 17.3267 20.9253 20.1087Z" stroke="#0E0E0F" stroke-width="0.8" stroke-miterlimit="10" />
        <path d="M23.5983 19.0117H21.5442V16.9576C21.5442 16.7796 21.4043 16.6396 21.2262 16.6396H19.8463C19.6682 16.6396 19.5283 16.7796 19.5283 16.9576V19.0117H17.4742C17.2962 19.0117 17.1562 19.1516 17.1562 19.3297V20.7096C17.1562 20.8877 17.2962 21.0276 17.4742 21.0276H19.5283V23.0817C19.5283 23.2597 19.6682 23.3996 19.8463 23.3996H21.2262C21.4043 23.3996 21.5442 23.2597 21.5442 23.0817V21.0276H23.5983C23.7763 21.0276 23.9163 20.8877 23.9163 20.7096V19.3297C23.9163 19.1516 23.7763 19.0117 23.5983 19.0117Z" fill="#0E0E0F" stroke="#0E0E0F" stroke-width="0.8" stroke-miterlimit="10" />
      </svg>

    ),
    Admin: (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
        <path d="M12.9984 12.9996C16.2293 12.9996 18.8484 10.6715 18.8484 7.79961C18.8484 4.92773 16.2293 2.59961 12.9984 2.59961C9.76757 2.59961 7.14844 4.92773 7.14844 7.79961C7.14844 10.6715 9.76757 12.9996 12.9984 12.9996Z" stroke="#0E0E0F" stroke-width="0.8" stroke-miterlimit="10" />
        <path d="M22.75 19.0271C22.638 19.1355 22.5207 19.244 22.4033 19.3479C20.0351 21.4443 16.6963 22.75 13 22.75C9.30375 22.75 5.96485 21.4443 3.59669 19.3479C3.47935 19.244 3.36201 19.1355 3.25 19.0271C3.25 16.4066 4.63676 13.628 8.68504 13C9.98646 13.8223 11.1972 14.1657 13.0053 14.1657C14.8135 14.1657 16.1842 13.8133 17.3096 13.0136C21.5019 13.6958 22.75 16.6099 22.75 19.0271Z" stroke="#0E0E0F" stroke-width="0.8" stroke-miterlimit="10" />
      </svg>

    ),
    Guest: (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
        <path d="M9.15146 23.2748C9.10986 23.0512 9.10466 22.8172 9.10466 22.5936C9.10466 22.1152 9.10466 21.6316 9.10466 21.1532C9.10466 20.1912 9.10466 19.2344 9.10466 18.2724C9.10466 16.0312 9.10466 13.7952 9.10466 11.554C8.59506 12.282 8.08546 13.01 7.57586 13.738C7.48226 13.8732 7.38346 14.0084 7.24826 14.102C7.09746 14.2008 6.91026 14.232 6.73346 14.2164C6.39026 14.1852 6.07306 13.9668 5.91186 13.6652C5.69346 13.2388 5.83906 12.8332 6.07826 12.4588C6.96746 11.0756 7.87746 9.69763 8.70426 8.27283C9.16706 7.47723 9.74426 6.82203 10.6387 6.48923C11.5695 6.14603 12.6615 6.24483 13.4935 6.78043C14.3983 7.35763 14.9755 8.32483 15.5059 9.23483C16.1247 10.2904 16.7851 11.3252 17.4819 12.334C17.8407 12.854 18.1319 13.608 17.5703 14.1228C16.6551 14.96 15.8023 13.2544 15.4019 12.7084C15.1003 12.2924 14.7987 11.8764 14.4971 11.4604C14.4971 13.2648 14.4971 15.0744 14.4971 16.8788C14.4971 18.7976 14.4971 20.7216 14.5075 22.6404C14.5075 22.9836 14.5075 23.332 14.3879 23.6544C14.2475 24.0236 13.8627 24.3616 13.4415 24.3408C13.0099 24.3148 12.6563 23.9248 12.5055 23.5504C12.3495 23.1708 12.3183 22.7496 12.2975 22.3388C12.2091 20.8724 12.1155 19.4008 12.0167 17.9344C11.9699 17.2584 11.9231 16.5824 11.8711 15.9012C11.6787 18.1216 11.4447 20.3472 11.3199 22.5728C11.2835 23.1916 11.1431 23.7636 10.5711 24.0964C10.3683 24.216 10.1239 24.2836 9.89506 24.2264C9.46866 24.1172 9.22426 23.6648 9.15146 23.2748Z" stroke="#0E0E0F" stroke-width="0.8" stroke-miterlimit="10" />
        <path d="M18.763 23.8732H16.943C16.137 23.8732 15.4766 23.2128 15.4766 22.4068V18.9644C15.4766 18.1584 16.137 17.498 16.943 17.498H18.763C19.569 17.498 20.2294 18.1584 20.2294 18.9644V22.4068C20.2242 23.2128 19.569 23.8732 18.763 23.8732Z" stroke="#0E0E0F" stroke-width="0.8" stroke-miterlimit="10" />
        <path d="M16.9297 17.5033V14.4821C16.9297 14.4821 17.8501 13.6917 18.7705 14.4821V17.5033" stroke="#0E0E0F" stroke-width="0.8" stroke-miterlimit="10" />
        <path d="M11.8504 5.66755C13.1571 5.66755 14.2164 4.60825 14.2164 3.30155C14.2164 1.99484 13.1571 0.935547 11.8504 0.935547C10.5437 0.935547 9.48438 1.99484 9.48438 3.30155C9.48438 4.60825 10.5437 5.66755 11.8504 5.66755Z" stroke="#0E0E0F" stroke-width="0.8" stroke-miterlimit="10" />
        <path d="M17.2911 22.9162C17.1715 22.937 17.0571 22.859 17.0363 22.7394L16.4799 19.5414C16.4591 19.4218 16.5371 19.3074 16.6567 19.2866C16.7763 19.2658 16.8907 19.3438 16.9115 19.4634L17.4679 22.6614C17.4887 22.781 17.4107 22.8954 17.2911 22.9162Z" fill="#0E0E0F" />
        <path d="M18.4159 22.9162C18.5355 22.937 18.6499 22.859 18.6707 22.7394L19.2271 19.5414C19.2479 19.4218 19.1699 19.3074 19.0503 19.2866C18.9307 19.2658 18.8163 19.3438 18.7955 19.4634L18.2391 22.6614C18.2131 22.781 18.2963 22.8954 18.4159 22.9162Z" fill="#0E0E0F" />
        <path d="M17.8512 22.9423C17.7316 22.9423 17.6328 22.8435 17.6328 22.7239V19.4791C17.6328 19.3595 17.7316 19.2607 17.8512 19.2607C17.9708 19.2607 18.0696 19.3595 18.0696 19.4791V22.7239C18.0696 22.8435 17.9708 22.9423 17.8512 22.9423Z" fill="#0E0E0F" />
      </svg>
    ),
    Accountant: (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
        <path d="M9.4207 13.775C11.6206 13.775 13.4039 11.9917 13.4039 9.79179C13.4039 7.59193 11.6206 5.80859 9.4207 5.80859C7.22084 5.80859 5.4375 7.59193 5.4375 9.79179C5.4375 11.9917 7.22084 13.775 9.4207 13.775Z" stroke="#0E0E0F" stroke-width="0.7" stroke-miterlimit="10" />
        <path d="M16.1295 19.0214C16.0515 19.1098 15.9735 19.1982 15.8903 19.2814C14.2575 20.987 11.9591 22.0478 9.41633 22.0478C6.87353 22.0478 4.57512 20.9818 2.94232 19.2814C2.85912 19.1982 2.78113 19.1098 2.70312 19.0214C2.70312 16.8894 3.65473 14.6326 6.44193 14.123C7.33633 14.7886 8.17353 15.0694 9.41633 15.0694C10.6591 15.0694 11.6055 14.7834 12.3803 14.1334C15.2715 14.6898 16.1295 17.061 16.1295 19.0214Z" stroke="#0E0E0F" stroke-width="0.7" stroke-miterlimit="10" />
        <path d="M25.2424 10.832H16.8672V20.2804H25.2424V10.832Z" stroke="#0E0E0F" stroke-width="0.5" stroke-miterlimit="10" />
        <path d="M24.4905 11.5303H17.5234V13.7107H24.4905V11.5303Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M18.8237 14.3916H17.5234V15.6919H18.8237V14.3916Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M20.6831 14.3916H19.3828V15.6919H20.6831V14.3916Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M18.8237 16.2832H17.5234V17.5835H18.8237V16.2832Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M20.6831 16.2832H19.3828V17.5835H20.6831V16.2832Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M22.6362 14.3916H21.3359V15.6919H22.6362V14.3916Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M24.4956 14.3916H23.1953V15.6919H24.4956V14.3916Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M18.8237 18.1738H17.5234V19.4741H18.8237V18.1738Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M20.6831 18.1738H19.3828V19.4741H20.6831V18.1738Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M22.6362 16.2656H21.3359V17.5659H22.6362V16.2656Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M22.6362 18.1562H21.3359V19.4565H22.6362V18.1562Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3877 19.5757H23.394C23.2862 19.5757 23.1953 19.4848 23.1953 19.377V16.3335C23.1953 16.2256 23.2862 16.1348 23.394 16.1348H24.3877C24.4956 16.1348 24.5864 16.2256 24.5864 16.3335V19.377C24.5864 19.4905 24.4956 19.5757 24.3877 19.5757Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
        <path d="M21.1181 7.86256V8.04456H21.3625V8.26816H21.1025C21.0765 8.42416 21.0141 8.53336 20.9101 8.60096C20.8113 8.66856 20.6553 8.70496 20.4525 8.70496V8.83496C20.4525 8.91296 20.4681 8.97016 20.4993 9.01696C20.5305 9.05856 20.5825 9.08456 20.6605 9.08456C20.7333 9.08456 20.7853 9.06376 20.8165 9.02216C20.8477 8.98056 20.8633 8.91816 20.8633 8.84016H21.1805C21.1753 9.00136 21.1285 9.13136 21.0401 9.21456C20.9517 9.30296 20.8217 9.34456 20.6605 9.34456C20.4889 9.34456 20.3589 9.29776 20.2653 9.20416C20.1769 9.11056 20.1301 8.97536 20.1301 8.79856V8.47616C20.3017 8.47616 20.4317 8.47096 20.5149 8.46056C20.5981 8.45016 20.6605 8.43456 20.6969 8.40336C20.7385 8.37216 20.7645 8.32536 20.7749 8.26296H20.0469V8.03936H20.7957V7.85736H20.0573V7.61816H21.3625V7.85736H21.1181V7.86256Z" fill="#0E0E0F" />
        <path d="M20.7003 10.213C21.6825 10.213 22.4787 9.41683 22.4787 8.43465C22.4787 7.45247 21.6825 6.65625 20.7003 6.65625C19.7181 6.65625 18.9219 7.45247 18.9219 8.43465C18.9219 9.41683 19.7181 10.213 20.7003 10.213Z" stroke="#0E0E0F" stroke-width="0.4" stroke-miterlimit="10" />
      </svg>

    )
  };
  const handleRoleChange = async (user, newRole) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/updaterole/${user._id}`,
        { ...user, role: newRole },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );

      const updatedUser = response.data;
      setUserList((prevList) =>
        prevList.map((u) =>
          u._id === updatedUser._id ? updatedUser : u
        )
      );

      setOpenDropdownId(null);
      setShouldRefetch((prev) => !prev);
      setShowPopup(true);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const handleClosePopup = () => {
    // Close the popup
    setShowPopup(false);
  };

  const toggleDropdown = (userId, event) => {
    if (changeRoleButtonRefs.current[userId]) {
      const rect = changeRoleButtonRefs.current[userId].getBoundingClientRect();
      setPopupPosition({
        bottom: window.innerHeight - rect.top + 100,
        left: rect.left + window.scrollX,
      });
    }
    setOpenDropdownId(openDropdownId === userId ? null : userId);
  };

  const handleDeleteClick = (userId) => {
    setSelectedUserId(userId);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const customerid = localStorage.getItem("customerid");
      const token = localStorage.getItem("token");

      if (!customerid || !token) {
        console.error("Customer ID or token is missing");
        return;
      }

      try {
        const userResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/getusers`,
          { customer_id: customerid },
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );
        setUserList(userResponse.data);
        console.log("User List:", userResponse.data);
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response?.statusText || error.message
        );
      }
    };

    fetchData();
  }, [shouldRefetch]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleConfirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/delterole/${selectedUserId}`,
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );
      if (response.status === 200) {
        setShowDeleteModal(false);
        setUserList(UserList.filter((user) => user._id !== selectedUserId));
      } else {
        console.error("Failed to delete user");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const getInitial = (name) => {
    return name.charAt(0).toUpperCase();
  };

  const filteredUserList = UserList.filter((user) =>
    user.Fullname.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const roleMapping = {
    "Superadmin": "superadmin",
    "Admin+": "adminpro",
    "Admin": "admin",
    "Guest": "guest",
    "Accountant": "accountant"
  };
  return (
    <div className="container mx-auto py-4 rounded-2xl">
      <h1 className="text-xl font-medium text-pasttext mb-4">User List</h1>
      <div className="flex mb-8 text-subtext">
        <div className="relative mb-4 w-[90%]">
          <input
            type="text"
            placeholder="Search User"
            value={searchTerm}
            onChange={handleSearchChange}
            // className="w-full py-4 px-16 pr-20 border placeholder:text-subtext rounded-full border-gray-300 text-base bg-background outline-primary"
            className="w-full py-4 px-14 pr-20 border border-borderborder rounded-full bg-background outline-primary placeholder:font-extralight font-extralight text-sm appearance-none"
          />
          {/* className="absolute left-4 top-1/2 transform -translate-y-1/2 pointer-events-none ml-2" */}
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute left-4 top-1/2 transform -translate-y-1/2 pointer-events-none ml-2">
            <g clip-path="url(#clip0_972_5167)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.62694 1.83301C8.38449 1.83311 7.16007 2.13034 6.05584 2.69989C4.95162 3.26945 3.99961 4.09481 3.27924 5.10712C2.55887 6.11942 2.09103 7.28933 1.91476 8.51921C1.73848 9.7491 1.85888 11.0033 2.2659 12.1772C2.67293 13.3511 3.35478 14.4106 4.25458 15.2674C5.15437 16.1242 6.246 16.7534 7.43841 17.1025C8.63082 17.4516 9.88942 17.5104 11.1092 17.2742C12.329 17.0379 13.4746 16.5134 14.4504 15.7443L17.7981 19.092C17.971 19.259 18.2025 19.3514 18.4429 19.3493C18.6832 19.3472 18.9132 19.2508 19.0831 19.0808C19.2531 18.9109 19.3495 18.681 19.3516 18.4406C19.3536 18.2003 19.2613 17.9687 19.0943 17.7958L15.7466 14.4482C16.6523 13.2992 17.2162 11.9185 17.3738 10.4641C17.5314 9.00964 17.2763 7.54019 16.6378 6.22393C15.9993 4.90766 15.0031 3.79775 13.7632 3.02122C12.5234 2.24469 11.0899 1.83291 9.62694 1.83301ZM3.66861 9.62467C3.66861 8.04443 4.29636 6.5289 5.41376 5.4115C6.53117 4.29409 8.04669 3.66634 9.62694 3.66634C11.2072 3.66634 12.7227 4.29409 13.8401 5.4115C14.9575 6.5289 15.5853 8.04443 15.5853 9.62467C15.5853 11.2049 14.9575 12.7204 13.8401 13.8379C12.7227 14.9553 11.2072 15.583 9.62694 15.583C8.04669 15.583 6.53117 14.9553 5.41376 13.8379C4.29636 12.7204 3.66861 11.2049 3.66861 9.62467Z" fill="#A49F9F" />
            </g>
            <defs>
              <clipPath id="clip0_972_5167">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>

        </div>
        <button className="h-14 justify-end ml-10 border-2 border-borderborder w-1/5 rounded-2xl">
          Total Users:
          <span className="text-primary text-xl font-semibold">
            {" "}
            {filteredUserList.length}
          </span>
        </button>
      </div>
      <div className="overflow-auto rounded-2xl border-2 border-borderborder max-h-[400px]"> {/* Adjust max-h to your preference */}
        <table ref={tableRef} className="min-w-full bg-background rounded-2xl p-4">
          <thead className="text-optionalcolor text-sm">
            <tr>
              <th className="py-6 pl-14 text-left font-normal">Name</th>
              <th className="py-6 px-6 font-normal">Role</th>
              <th className="py-6 pr-40 text-right font-normal">Actions</th>
            </tr>
          </thead>
          <tbody className="text-#1B1818 text-sm font-light">
            {filteredUserList && filteredUserList.length > 0 ? (
              filteredUserList.map((user) => (
                <tr key={user._id} className="hover:bg-gray-100">
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="mr-5 w-8 h-8 border-primary border-2 text-primary rounded-full flex items-center justify-center">
                        {getInitial(user.Fullname)}
                      </div>
                      <span className="font-normal text-base text-#1B1818">{user.Fullname}</span>
                    </div>
                  </td>
                  <td className="py-3 px-6 flex">
                    <span className="bg-role-fill-2 ml-32 text-black py-1 px-3 rounded-full text-base">
                      {user.role === "admin"
                        ? "Admin"
                        : user.role === "superadmin"
                          ? "Superadmin"
                          : user.role === "adminpro"
                            ? "Admin+"
                            : user.role === "guest"
                              ? "Guest"
                              : user.role}
                    </span>
                  </td>
                  <td className="py-3 px-6 text-right">
                    <div className="flex items-center justify-end space-x-3">
                      <div className="relative inline-block text-left">
                        <button
                          ref={el => changeRoleButtonRefs.current[user._id] = el}
                          className="flex items-center text-servicetext hover:text-gray-900 text-base"
                          onClick={(event) => toggleDropdown(user._id, event)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-6 text-servicetext"
                          >
                            <path
                              d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8011 7.90298 21.8755 7.79146 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                              fill="#262326"
                            />
                          </svg>
                          Change Role
                        </button>
                        {openDropdownId === user._id && (

                          <div
                            ref={dropdownRef}
                            className="absolute rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                            style={{
                              bottom: `${popupPosition.bottom}px`,
                              left: `${popupPosition.left}px`,
                              position: 'fixed',
                              transform: 'translateY(50%)'
                            }}
                          >
                            <div
                              className="py-1"
                              role="menu"
                              aria-orientation="vertical"
                              aria-labelledby="options-menu"
                            >
                              {[
                                "Superadmin",
                                "Admin+",
                                "Admin",
                                "Guest",
                                "Accountant",
                              ].map((role) => (
                                <button
                                  key={role}
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 text-left"
                                  role="menuitem"
                                  onClick={() => handleRoleChange(user, roleMapping[role])}
                                >
                                  {roleIcons[role]}
                                  {role}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <button
                        className="flex items-center ml-4 text-servicetext text-base"
                        onClick={() => handleDeleteClick(user._id)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-6 ml-11 text-servicetext"
                        >
                          <path
                            d="M4 20L12 12L20 20M20 4L11.9985 12L4 4"
                            stroke="#262326"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Remove User
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center py-3 px-6">
                  No users found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
          <div className="relative w-[29%] h-1/4 bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between">
            <h3 className="text-lg font-medium text-center">
              Remove User Permanently?
            </h3>
            <div className="flex items-end justify-center mt-auto">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 text-gray-700 px-7 py-2 rounded mr-10"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="bg-primary text-white px-10 py-2 rounded ml-10"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
          <div className="bg-white p-6 rounded-lg text-center flex flex-col items-center justify-center h-1/2 w-1/5">
            <svg width="127" height="122" viewBox="0 0 127 122" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_970_4517)">
                <rect x="16" y="12" width="89" height="90" rx="44.5" fill="#FEF9F5" fill-opacity="0.44" shape-rendering="crispEdges" />
                <rect x="16.5" y="12.5" width="88" height="89" rx="44" stroke="#D0F0C0" stroke-opacity="0.11" shape-rendering="crispEdges" />
                <rect x="21.5" y="18.5" width="77" height="77" rx="38.5" stroke="#BDE0ED" stroke-opacity="0.15" />
                <rect x="27" y="24" width="66" height="66" rx="33" fill="#3CABD4" />
                <path d="M54.8896 70L43 57.6757L45.9724 54.5946L54.8896 63.8378L74.0276 44L77 47.0811L54.8896 70Z" fill="white" />
                <g clip-path="url(#clip0_970_4517)">
                  <path d="M30.1782 90.8817C30.2023 90.8154 30.2462 90.758 30.3041 90.7175C30.3619 90.677 30.4307 90.6553 30.5013 90.6553C30.5719 90.6553 30.6408 90.677 30.6986 90.7175C30.7565 90.758 30.8004 90.8154 30.8245 90.8817L31.1531 91.7796C31.4108 92.4835 31.8189 93.1227 32.349 93.6528C32.879 94.1828 33.5182 94.5909 34.2221 94.8486L35.1193 95.1772C35.1856 95.2013 35.243 95.2453 35.2835 95.3031C35.324 95.3609 35.3458 95.4298 35.3458 95.5004C35.3458 95.571 35.324 95.6399 35.2835 95.6977C35.243 95.7555 35.1856 95.7994 35.1193 95.8235L34.2221 96.1521C33.5182 96.4098 32.879 96.8179 32.349 97.348C31.8189 97.878 31.4108 98.5172 31.1531 99.2211L30.8245 100.118C30.8004 100.185 30.7565 100.242 30.6986 100.283C30.6408 100.323 30.5719 100.345 30.5013 100.345C30.4307 100.345 30.3619 100.323 30.3041 100.283C30.2462 100.242 30.2023 100.185 30.1782 100.118L29.8496 99.2211C29.5919 98.5172 29.1838 97.878 28.6537 97.348C28.1237 96.8179 27.4845 96.4098 26.7806 96.1521L25.8827 95.8235C25.8164 95.7994 25.759 95.7555 25.7185 95.6977C25.678 95.6399 25.6562 95.571 25.6562 95.5004C25.6562 95.4298 25.678 95.3609 25.7185 95.3031C25.759 95.2453 25.8164 95.2013 25.8827 95.1772L26.7806 94.8486C27.4845 94.5909 28.1237 94.1828 28.6537 93.6528C29.1838 93.1227 29.5919 92.4835 29.8496 91.7796L30.1782 90.8817Z" fill="#BDE0ED" />
                </g>
                <g clip-path="url(#clip1_970_4517)">
                  <path d="M96.7611 80.7214C96.7414 80.6671 96.7055 80.6202 96.6582 80.5871C96.6109 80.5539 96.5545 80.5361 96.4968 80.5361C96.439 80.5361 96.3826 80.5539 96.3353 80.5871C96.288 80.6202 96.2521 80.6671 96.2324 80.7214L95.9635 81.4561C95.7526 82.032 95.4188 82.555 94.9851 82.9886C94.5514 83.4223 94.0284 83.7562 93.4525 83.9671L92.7185 84.2359C92.6642 84.2556 92.6172 84.2916 92.5841 84.3389C92.5509 84.3862 92.5332 84.4425 92.5332 84.5003C92.5332 84.5581 92.5509 84.6144 92.5841 84.6617C92.6172 84.709 92.6642 84.745 92.7185 84.7647L93.4525 85.0336C94.0284 85.2444 94.5514 85.5783 94.9851 86.012C95.4188 86.4456 95.7526 86.9687 95.9635 87.5446L96.2324 88.2786C96.2521 88.3329 96.288 88.3798 96.3353 88.413C96.3826 88.4461 96.439 88.4639 96.4968 88.4639C96.5545 88.4639 96.6109 88.4461 96.6582 88.413C96.7055 88.3798 96.7414 88.3329 96.7611 88.2786L97.03 87.5446C97.2409 86.9687 97.5748 86.4456 98.0084 86.012C98.4421 85.5783 98.9651 85.2444 99.541 85.0336L100.276 84.7647C100.33 84.745 100.377 84.709 100.41 84.6617C100.443 84.6144 100.461 84.5581 100.461 84.5003C100.461 84.4425 100.443 84.3862 100.41 84.3389C100.377 84.2916 100.33 84.2556 100.276 84.2359L99.541 83.9671C98.9651 83.7562 98.4421 83.4223 98.0084 82.9886C97.5748 82.555 97.2409 82.032 97.03 81.4561L96.7611 80.7214Z" fill="#D0F0C0" />
                </g>
                <path d="M31.5336 16.3272C31.5684 16.2313 31.6319 16.1485 31.7154 16.09C31.799 16.0314 31.8985 16 32.0005 16C32.1025 16 32.202 16.0314 32.2856 16.09C32.3691 16.1485 32.4326 16.2313 32.4674 16.3272L32.9422 17.6245C33.3146 18.6415 33.9042 19.5651 34.67 20.331C35.4359 21.0968 36.3595 21.6864 37.3765 22.0588L38.6728 22.5336C38.7687 22.5684 38.8515 22.6319 38.9101 22.7154C38.9686 22.799 39 22.8985 39 23.0005C39 23.1025 38.9686 23.202 38.9101 23.2856C38.8515 23.3691 38.7687 23.4326 38.6728 23.4674L37.3765 23.9422C36.3595 24.3146 35.4359 24.9042 34.67 25.67C33.9042 26.4359 33.3146 27.3595 32.9422 28.3765L32.4674 29.6728C32.4326 29.7687 32.3691 29.8515 32.2856 29.9101C32.202 29.9686 32.1025 30 32.0005 30C31.8985 30 31.799 29.9686 31.7154 29.9101C31.6319 29.8515 31.5684 29.7687 31.5336 29.6728L31.0588 28.3765C30.6864 27.3595 30.0968 26.4359 29.331 25.67C28.5651 24.9042 27.6415 24.3146 26.6245 23.9422L25.3272 23.4674C25.2313 23.4326 25.1485 23.3691 25.09 23.2856C25.0314 23.202 25 23.1025 25 23.0005C25 22.8985 25.0314 22.799 25.09 22.7154C25.1485 22.6319 25.2313 22.5684 25.3272 22.5336L26.6245 22.0588C27.6415 21.6864 28.5651 21.0968 29.331 20.331C30.0968 19.5651 30.6864 18.6415 31.0588 17.6245L31.5336 16.3272Z" fill="#BDE0ED" />
                <path d="M22.6669 38.2337C22.6917 38.1652 22.7371 38.1061 22.7967 38.0643C22.8564 38.0224 22.9275 38 23.0004 38C23.0732 38 23.1443 38.0224 23.204 38.0643C23.2636 38.1061 23.309 38.1652 23.3338 38.2337L23.673 39.1604C23.939 39.8868 24.3602 40.5465 24.9072 41.0935C25.4542 41.6406 26.1139 42.0617 26.8403 42.3277L27.7663 42.6669C27.8348 42.6917 27.8939 42.7371 27.9358 42.7967C27.9776 42.8564 28 42.9275 28 43.0004C28 43.0732 27.9776 43.1443 27.9358 43.204C27.8939 43.2636 27.8348 43.309 27.7663 43.3338L26.8403 43.673C26.1139 43.939 25.4542 44.3602 24.9072 44.9072C24.3602 45.4542 23.939 46.1139 23.673 46.8403L23.3338 47.7663C23.309 47.8348 23.2636 47.8939 23.204 47.9358C23.1443 47.9776 23.0732 48 23.0004 48C22.9275 48 22.8564 47.9776 22.7967 47.9358C22.7371 47.8939 22.6917 47.8348 22.6669 47.7663L22.3277 46.8403C22.0617 46.1139 21.6406 45.4542 21.0935 44.9072C20.5465 44.3602 19.8868 43.939 19.1604 43.673L18.2337 43.3338C18.1652 43.309 18.1061 43.2636 18.0643 43.204C18.0224 43.1443 18 43.0732 18 43.0004C18 42.9275 18.0224 42.8564 18.0643 42.7967C18.1061 42.7371 18.1652 42.6917 18.2337 42.6669L19.1604 42.3277C19.8868 42.0617 20.5465 41.6406 21.0935 41.0935C21.6406 40.5465 22.0617 39.8868 22.3277 39.1604L22.6669 38.2337Z" fill="#D0F0C0" />
                <g clip-path="url(#clip2_970_4517)">
                  <path d="M107.292 34.5611C107.307 34.5189 107.335 34.4824 107.372 34.4566C107.409 34.4308 107.452 34.417 107.497 34.417C107.542 34.417 107.586 34.4308 107.623 34.4566C107.66 34.4824 107.688 34.5189 107.703 34.5611L107.912 35.1325C108.076 35.5804 108.336 35.9872 108.673 36.3245C109.01 36.6618 109.417 36.9215 109.865 37.0855L110.436 37.2946C110.478 37.3099 110.515 37.3379 110.54 37.3747C110.566 37.4115 110.58 37.4553 110.58 37.5002C110.58 37.5452 110.566 37.589 110.54 37.6258C110.515 37.6626 110.478 37.6905 110.436 37.7059L109.865 37.915C109.417 38.079 109.01 38.3387 108.673 38.676C108.336 39.0133 108.076 39.4201 107.912 39.868L107.703 40.4389C107.688 40.4812 107.66 40.5176 107.623 40.5434C107.586 40.5692 107.542 40.583 107.497 40.583C107.452 40.583 107.409 40.5692 107.372 40.5434C107.335 40.5176 107.307 40.4812 107.292 40.4389L107.083 39.868C106.919 39.4201 106.659 39.0133 106.322 38.676C105.984 38.3387 105.577 38.079 105.13 37.915L104.558 37.7059C104.516 37.6905 104.479 37.6626 104.454 37.6258C104.428 37.589 104.414 37.5452 104.414 37.5002C104.414 37.4553 104.428 37.4115 104.454 37.3747C104.479 37.3379 104.516 37.3099 104.558 37.2946L105.13 37.0855C105.577 36.9215 105.984 36.6618 106.322 36.3245C106.659 35.9872 106.919 35.5804 107.083 35.1325L107.292 34.5611Z" fill="#BDE0ED" />
                </g>
                <path d="M100.8 27.1402C100.815 27.0991 100.842 27.0636 100.878 27.0386C100.914 27.0135 100.956 27 101 27C101.044 27 101.087 27.0135 101.122 27.0386C101.158 27.0636 101.185 27.0991 101.2 27.1402L101.404 27.6962C101.563 28.1321 101.816 28.5279 102.144 28.8561C102.473 29.1843 102.868 29.437 103.304 29.5966L103.86 29.8001C103.901 29.815 103.936 29.8422 103.961 29.878C103.987 29.9138 104 29.9565 104 30.0002C104 30.0439 103.987 30.0866 103.961 30.1224C103.936 30.1582 103.901 30.1854 103.86 30.2003L103.304 30.4038C102.868 30.5634 102.473 30.8161 102.144 31.1443C101.816 31.4725 101.563 31.8683 101.404 32.3042L101.2 32.8598C101.185 32.9009 101.158 32.9364 101.122 32.9615C101.087 32.9865 101.044 33 101 33C100.956 33 100.914 32.9865 100.878 32.9615C100.842 32.9364 100.815 32.9009 100.8 32.8598L100.597 32.3042C100.437 31.8683 100.184 31.4725 99.8561 31.1443C99.5279 30.8161 99.1321 30.5634 98.6962 30.4038L98.1402 30.2003C98.0991 30.1854 98.0636 30.1582 98.0386 30.1224C98.0135 30.0866 98 30.0439 98 30.0002C98 29.9565 98.0135 29.9138 98.0386 29.878C98.0636 29.8422 98.0991 29.815 98.1402 29.8001L98.6962 29.5966C99.1321 29.437 99.5279 29.1843 99.8561 28.8561C100.184 28.5279 100.437 28.1321 100.597 27.6962L100.8 27.1402Z" fill="#D0F0C0" />
              </g>
              <defs>
                <filter id="filter0_d_970_4517" x="0.1" y="0.1" width="126.8" height="121.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="7.95" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.846667 0 0 0 0 0.846667 0 0 0 0 0.846667 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_970_4517" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_970_4517" result="shape" />
                </filter>
                <clipPath id="clip0_970_4517">
                  <rect width="11" height="11" fill="white" transform="translate(25 90)" />
                </clipPath>
                <clipPath id="clip1_970_4517">
                  <rect width="9" height="9" fill="white" transform="matrix(-1 0 0 1 101 80)" />
                </clipPath>
                <clipPath id="clip2_970_4517">
                  <rect width="7" height="7" fill="white" transform="translate(104 34)" />
                </clipPath>
              </defs>
            </svg>
            <h2 className=" mt-3 mb-12 font-medium">
              Role Changed Successfully!
            </h2>
            <button
              className="mb-4 bg-primary text-white py-2 px-10 rounded"
              onClick={handleClosePopup}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>

  );
};

export default UserList;
