import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Toggle2 from "../toggle2/Toggle2";
import SelfCleanPopup from "../POPUP/SelfCleanPopup";
import TemperatureLockPopup from "../POPUP/TemperatureLockPopup";
import AsidePage from "../AsidePage";
import Headerpage from "../Headerpage";
import SchedulePopup from "../POPUP/SchedulePopup";
import ChildLockPopup from "../POPUP/ChildLockPopup";
import axios from "axios";
const svgPaths = {
  "Schedule": (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-4">
      {/* SVG path for "Temperature Lock" */}
      <path d="M10 2.5C5.85938 2.5 2.5 5.85938 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10C17.5 5.85938 14.1406 2.5 10 2.5Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
      <path d="M10 5V10.625H13.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  ),
  "Child Lock": (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-4">
      <path d="M8.33464 9.99967C10.1763 9.99967 11.668 8.50801 11.668 6.66634C11.668 4.82467 10.1763 3.33301 8.33464 3.33301C6.49297 3.33301 5.0013 4.82467 5.0013 6.66634C5.0013 8.50801 6.49297 9.99967 8.33464 9.99967ZM8.33464 4.99967C8.77666 4.99967 9.20059 5.17527 9.51315 5.48783C9.82571 5.80039 10.0013 6.22431 10.0013 6.66634C10.0013 7.10837 9.82571 7.53229 9.51315 7.84485C9.20059 8.15741 8.77666 8.33301 8.33464 8.33301C7.40964 8.33301 6.66797 7.59134 6.66797 6.66634C6.66797 5.74134 7.41797 4.99967 8.33464 4.99967ZM10.0013 16.6663H1.66797V14.1663C1.66797 11.9413 6.10964 10.833 8.33464 10.833C9.16797 10.833 10.318 10.9913 11.4263 11.2997C11.1763 11.7663 11.0263 12.283 11.0096 12.8247C10.193 12.583 9.25963 12.4163 8.33464 12.4163C5.85964 12.4163 3.2513 13.633 3.2513 14.1663V15.083H10.0013V16.6663ZM17.3346 14.1663V12.9163C17.3346 11.7497 16.168 10.833 15.0013 10.833C13.8346 10.833 12.668 11.7497 12.668 12.9163V14.1663C12.168 14.1663 11.668 14.6663 11.668 15.1663V18.083C11.668 18.6663 12.168 19.1663 12.668 19.1663H17.2513C17.8346 19.1663 18.3346 18.6663 18.3346 18.1663V15.2497C18.3346 14.6663 17.8346 14.1663 17.3346 14.1663ZM16.2513 14.1663H13.7513V12.9163C13.7513 12.2497 14.3346 11.833 15.0013 11.833C15.668 11.833 16.2513 12.2497 16.2513 12.9163V14.1663Z" fill="black" />
    </svg>
  ),
  "Temperature Lock": (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-4">
      <g clip-path="url(#clip0_972_4885)">
        <path d="M6.57854 18.75C5.58691 18.7504 4.62287 18.4328 3.83604 17.8465C3.04921 17.2601 2.48359 16.4378 2.22697 15.5072C1.97036 14.5766 2.03709 13.5896 2.41682 12.6996C2.79656 11.8096 3.46805 11.0663 4.32707 10.585L4.64871 10.405V3.125C4.64695 2.68275 4.80657 2.2542 5.09923 1.91547C5.39188 1.57674 5.79863 1.34974 6.24723 1.2748C6.69584 1.19986 7.15726 1.28182 7.54955 1.50613C7.94185 1.73044 8.23963 2.08257 8.39 2.5H6.57854V3.75H8.50836V5H6.57854V6.25H8.50836V7.5H6.57854V8.75H8.50836V10.405L8.83 10.585C9.68903 11.0663 10.3605 11.8096 10.7402 12.6996C11.12 13.5896 11.1867 14.5766 10.9301 15.5072C10.6735 16.4378 10.1079 17.2601 9.32103 17.8465C8.5342 18.4328 7.57016 18.7504 6.57854 18.75ZM9.79491 9.6975V3.125C9.79491 2.2962 9.45604 1.50134 8.85286 0.915291C8.24967 0.32924 7.43157 0 6.57854 0C5.7255 0 4.9074 0.32924 4.30422 0.915291C3.70103 1.50134 3.36216 2.2962 3.36216 3.125V9.6975C2.32942 10.3679 1.54597 11.3433 1.12752 12.4795C0.709067 13.6157 0.677802 14.8526 1.03834 16.0074C1.39888 17.1622 2.13211 18.1737 3.12973 18.8926C4.12735 19.6115 5.33648 19.9996 6.57854 19.9996C7.82059 19.9996 9.02972 19.6115 10.0273 18.8926C11.025 18.1737 11.7582 17.1622 12.1187 16.0074C12.4793 14.8526 12.448 13.6157 12.0296 12.4795C11.6111 11.3433 10.8277 10.3679 9.79491 9.6975Z" fill="black" />
        <path d="M6.41406 11.75C7.11026 11.75 7.77794 12.0266 8.27022 12.5188C8.7625 13.0111 9.03906 13.6788 9.03906 14.375C9.03906 15.0712 8.7625 15.7389 8.27022 16.2312C7.77794 16.7234 7.11026 17 6.41406 17C5.71787 17 5.05019 16.7234 4.55791 16.2312C4.06562 15.7389 3.78906 15.0712 3.78906 14.375C3.78906 13.6788 4.06562 13.0111 4.55791 12.5188C5.05019 12.0266 5.71787 11.75 6.41406 11.75Z" stroke="black" />
        <rect x="14.0781" y="7.86865" width="3" height="4" rx="0.5" stroke="black" />
        <rect x="13.0781" y="10.8687" width="5" height="4" rx="0.5" fill="black" stroke="black" />
      </g>
      <defs>
        <clipPath id="clip0_972_4885">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  ),
  "View Analytics": (
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-4">
      <path d="M14.3333 1L9.08758 6.24575C8.82357 6.50976 8.69156 6.64177 8.53934 6.69123C8.40545 6.73473 8.26122 6.73473 8.12732 6.69123C7.9751 6.64177 7.8431 6.50976 7.57909 6.24575L5.75425 4.42091C5.49024 4.1569 5.35823 4.0249 5.20601 3.97544C5.07212 3.93193 4.92788 3.93193 4.79399 3.97544C4.64177 4.0249 4.50976 4.1569 4.24575 4.42091L1 7.66667M14.3333 1H9.66667M14.3333 1V5.66667" stroke="#101010" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  ),
  "Self Clean": (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-4">
      <g clip-path="url(#clip0_972_4903)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2383 14.5146L15.6758 15.9146L16.9883 16.1479L15.6758 16.6146L15.2383 18.0146L14.8008 16.6146L13.4883 16.1479L14.8008 15.9146L15.2383 14.5146Z" fill="#060606" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.87109 1.69336L6.62109 4.04119L8.87109 4.8238L6.62109 5.34555L5.87109 7.69335L5.12109 5.34555L2.87109 4.8238L5.12109 4.04119L5.87109 1.69336Z" fill="#060606" />
        <path d="M9.99226 18.6318C8.96491 18.4489 5.37546 16.2861 4.52344 15.1187" stroke="#060606" stroke-width="1.5" stroke-miterlimit="10" />
        <path d="M10.112 15.0981C10.112 15.0981 9.32603 17.0678 8.10938 17.7887" stroke="#060606" stroke-width="1.5" stroke-miterlimit="10" />
        <path d="M7.59823 12.1108C7.59823 12.1108 6.61088 14.3962 5.01562 15.206" stroke="#060606" stroke-width="1.5" stroke-miterlimit="10" />
        <path d="M4.79532 15.3124C4.11998 14.9019 2.95065 14.1133 2.69531 13.9711C3.82635 13.3986 4.62806 12.574 5.28183 11.5296C5.93553 10.4851 6.49863 9.42456 7.77043 8.58691C8.77508 9.24951 9.13783 9.31406 10.8345 10.2934C14.1539 12.142 13.3761 13.3578 13.6838 12.155C13.1519 14.0918 12.4195 17.1537 11.186 19.3524C10.7326 19.2717 9.39868 18.357 8.79728 18.0564" stroke="#060606" stroke-width="1.5" stroke-miterlimit="10" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0075 2.396C16.3603 1.87084 16.9896 2.30544 16.6368 2.83059L12.5409 8.48641L11.9883 8.06816L16.0075 2.396Z" stroke="#060606" stroke-width="1.5" stroke-miterlimit="10" />
        <path d="M13.895 12.2692L8 8.39156C8.70305 7.32281 9.86895 7.43346 10.364 7.62241L13.4294 9.63876C14.0112 10.295 14.2165 11.5237 13.6516 12.3825" stroke="#060606" stroke-width="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_972_4903">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  ),
  "Rename Location": (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-4">
      <path d="M12.5 13.3332L9.16667 16.6665H17.5V13.3332H12.5ZM10.05 5.99151L2.5 13.5415V16.6665H5.625L13.175 9.11651L10.05 5.99151ZM4.93333 14.9998H4.16667V14.2332L10.05 8.33318L10.8333 9.11651L4.93333 14.9998ZM15.5917 6.69985C15.9167 6.37485 15.9167 5.83318 15.5917 5.52485L13.6417 3.57485C13.4855 3.41964 13.2743 3.33252 13.0542 3.33252C12.834 3.33252 12.6228 3.41964 12.4667 3.57485L10.9417 5.09985L14.0667 8.22485L15.5917 6.69985Z" fill="black" />
    </svg>
  ),
};

export default function Controls() {
  const [visibleAddressId, setVisibleAddressId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showSchedulePopup, setshowSchedulePopup] = useState(false)
  const [selectedTime, setSelectedTime] = useState({
    hour: "7",
    minute: "00",
    period: "AM",
  });
  const [selectedFrequency, setSelectedFrequency] = useState("custom");
  const [selectedDays, setSelectedDays] = useState(["T", "W", "T"]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [showTempLockPopup, setShowTempLockPopup] = useState(false);
  const [showChildLockPopup, setshowChildLockPopup] = useState(false)
  const [addressesData, setAddressesData] = useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const dropdownRef = useRef(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filteredAddresses, setFilteredAddresses] = useState(addressesData);
  useEffect(() => {
    setFilteredAddresses(addressesData);
  }, [addressesData]);
  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchText(searchValue);

    const filtered = addressesData.filter(address =>
      address.line2.toLowerCase().includes(searchValue)
    );

    setFilteredAddresses(filtered);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDivVisibility = (id) => {
    setVisibleAddressId((prevId) => (prevId === id ? null : id));
  };


  const handleSave = () => {
    setShowPopup(false);
    console.log("Saved:", { selectedTime, selectedFrequency, selectedDays });
  };

  const handleScheduleclose = () => {
    setshowSchedulePopup(false)
  }
  const handletempclose = () => {
    setShowTempLockPopup(false)
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };
  const handleChildcancel = () => {
    setshowChildLockPopup(false)
  }

  const toggleDropdown = (id, event) => {
    event.stopPropagation();

    if (openDropdownId === id) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(id);
      const rect = event.currentTarget.getBoundingClientRect();
      const dropdownWidth = 100; // Estimate or measure your dropdown width
      const offset = 50; // Adjust this value to move the dropdown further left

      let left = rect.left - dropdownWidth - offset;
      if (left < 0) {
        left = 0; // Prevent going off-screen on the left
      }

      setPopupPosition({
        top: rect.top + window.scrollY,
        left: left + window.scrollX,
      });
    }
  };
  const handleOptionClick = (option, deviceId) => {
    console.log(`Selected option: ${option} for device: ${deviceId}`);
    switch (option) {
      case "Self Clean":
        setShowPopup(true);
        setSelectedDeviceId(deviceId);
        break;
      case "Temperature Lock":
        setShowTempLockPopup(true);  // Corrected line
        break;
      case "Schedule":
        setshowSchedulePopup(true);
        break;
      case "Child Lock":
        setshowChildLockPopup(true)
        break;

      default:
      // setGeneralPopupContent(option);
      // setShowGeneralPopup(true);
    }
    setOpenDropdownId(null); // Close the dropdown after selection
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/shipping/getaddress`,
      headers: {
        "x-auth-token": localStorage.getItem("token")
      }
    }).then((res) => {
      setAddressesData(res.data)
    }).catch((err) => {
      console.log(err)
    })


    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/user/b2blogin/user/devices`,
      headers: {
        "x-auth-token": localStorage.getItem("token")
      }
    }).then((res) => {
      setDevicesData(res.data)
    }).catch((err) => {
      console.log(err)
    })

  }, [])

  const getDevicesForAddress = (addressId) => {
    const filteredDevices = devicesData.filter(device => device.addressid === addressId);
    console.log(filteredDevices,"devices")
    return filteredDevices;
  };
  

  return (
    <div className="flex min-h-screen bg-background">
      <AsidePage />
      <main className="flex-1 p-6 overflow-y-auto ml-64">
        <Headerpage />
        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Search location"
            className="w-full py-4 px-16 pr-20 border placeholder:text-subtext rounded-full border-gray-300 text-base bg-background outline-primary"
            // className="w-full py-4 px-14 pr-20 border border-borderborder rounded-full bg-background outline-primary placeholder:font-extralight font-extralight text-sm appearance-none"
            value={searchText}
            onChange={handleSearch}
          />
          {/* className="absolute left-4 top-1/2 transform -translate-y-1/2 pointer-events-none ml-2" */}
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute left-4 top-1/2 transform -translate-y-1/2 pointer-events-none ml-2">
            <g clip-path="url(#clip0_972_5167)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.62694 1.83301C8.38449 1.83311 7.16007 2.13034 6.05584 2.69989C4.95162 3.26945 3.99961 4.09481 3.27924 5.10712C2.55887 6.11942 2.09103 7.28933 1.91476 8.51921C1.73848 9.7491 1.85888 11.0033 2.2659 12.1772C2.67293 13.3511 3.35478 14.4106 4.25458 15.2674C5.15437 16.1242 6.246 16.7534 7.43841 17.1025C8.63082 17.4516 9.88942 17.5104 11.1092 17.2742C12.329 17.0379 13.4746 16.5134 14.4504 15.7443L17.7981 19.092C17.971 19.259 18.2025 19.3514 18.4429 19.3493C18.6832 19.3472 18.9132 19.2508 19.0831 19.0808C19.2531 18.9109 19.3495 18.681 19.3516 18.4406C19.3536 18.2003 19.2613 17.9687 19.0943 17.7958L15.7466 14.4482C16.6523 13.2992 17.2162 11.9185 17.3738 10.4641C17.5314 9.00964 17.2763 7.54019 16.6378 6.22393C15.9993 4.90766 15.0031 3.79775 13.7632 3.02122C12.5234 2.24469 11.0899 1.83291 9.62694 1.83301ZM3.66861 9.62467C3.66861 8.04443 4.29636 6.5289 5.41376 5.4115C6.53117 4.29409 8.04669 3.66634 9.62694 3.66634C11.2072 3.66634 12.7227 4.29409 13.8401 5.4115C14.9575 6.5289 15.5853 8.04443 15.5853 9.62467C15.5853 11.2049 14.9575 12.7204 13.8401 13.8379C12.7227 14.9553 11.2072 15.583 9.62694 15.583C8.04669 15.583 6.53117 14.9553 5.41376 13.8379C4.29636 12.7204 3.66861 11.2049 3.66861 9.62467Z" fill="#A49F9F" />
            </g>
            <defs>
              <clipPath id="clip0_972_5167">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mr-5 hover:cursor-pointer"
          onClick={handleClick}
        ></svg>
        {filteredAddresses.map((address) => (
          <div key={address._id} className="flex flex-col justify-between min-w-full border-2 rounded-xl border-borderborder text-2xl items-center mb-4" onClick={() => toggleDivVisibility(address._id)}>
            <div className="p-2 flex justify-between w-full items-center">
              <h1 className="p-2 text-lg font-normal">{address.line2}</h1>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-5 hover:cursor-pointer"
                onClick={(event) => toggleDropdown(address._id, event)}
              >
                <path
                  d="M12 17C12.5304 17 13.0391 17.2107 13.4142 17.5858C13.7893 17.9609 14 18.4696 14 19C14 19.5304 13.7893 20.0391 13.4142 20.4142C13.0391 20.7893 12.5304 21 12 21C11.4696 21 10.9609 20.7893 10.5858 20.4142C10.2107 20.0391 10 19.5304 10 19C10 18.4696 10.2107 17.9609 10.5858 17.5858C10.9609 17.2107 11.4696 17 12 17ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 3C12.5304 3 13.0391 3.21071 13.4142 3.58579C13.7893 3.96086 14 4.46957 14 5C14 5.53043 13.7893 6.03914 13.4142 6.41421C13.0391 6.78929 12.5304 7 12 7C11.4696 7 10.9609 6.78929 10.5858 6.41421C10.2107 6.03914 10 5.53043 10 5C10 4.46957 10.2107 3.96086 10.5858 3.58579C10.9609 3.21071 11.4696 3 12 3Z"
                  fill="black"
                />
              </svg>
              {openDropdownId === address._id && (
                <div
                  ref={dropdownRef}
                  className="absolute rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                  style={{
                    top: `${popupPosition.top}px`,
                    left: `${popupPosition.left}px`,
                    position: "fixed",
                  }}
                >
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    {[
                      "Child Lock",
                      "Temperature Lock",
                      "View Analytics",
                      "Self Clean",
                      "Rename Location"
                    ].map((option) => (
                      <button
                        key={option}
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                        role="menuitem"
                        onClick={() => handleOptionClick(option)}
                      >
                        {svgPaths[option]}
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {visibleAddressId === address._id && (
              <div
                className="w-full flex flex-col items-center"
                onClick={(event) => event.stopPropagation()}
              >
                {getDevicesForAddress(address._id).map((device) => (
                  <div
                    key={device.deviceid}
                    className="flex items-center justify-between border border-gray-300 rounded-lg w-[97%] mb-4 p-2"
                  >
                    <h1 className="text-center text-base font-normal">
                      {device.deviceName}
                    </h1>
                    <div className="flex">
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                        className="mr-4 rounded-lg"
                        onClick={() => handleOptionClick("Self Clean", device.deviceid)}>
                        <rect width="48" height="48" rx="7" fill="#E8F6DE" />
                        <path d="M24 15C19.0312 15 15 19.0312 15 24C15 28.9688 19.0312 33 24 33C28.9688 33 33 28.9688 33 24C33 19.0312 28.9688 15 24 15Z" stroke="#0E0E0F" stroke-width="1.5" stroke-miterlimit="10" />
                        <path d="M24 18V24.75H28.5" stroke="#0E0E0F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"className="mr-4 rounded-lg"onClick={() => handleOptionClick("Temperature Lock")}>
                        <rect width="48" height="48" rx="7" fill="#E8F6DE" />
                        <g clip-path="url(#clip0_981_5281)">
                          <path d="M19.8966 34.5C18.7066 34.5005 17.5498 34.1194 16.6056 33.4158C15.6614 32.7121 14.9827 31.7254 14.6747 30.6086C14.3668 29.4919 14.4469 28.3076 14.9025 27.2396C15.3582 26.1716 16.164 25.2796 17.1948 24.702L17.5808 24.486V15.75C17.5787 15.2193 17.7702 14.705 18.1214 14.2986C18.4726 13.8921 18.9607 13.6197 19.499 13.5298C20.0373 13.4398 20.5911 13.5382 21.0618 13.8074C21.5326 14.0765 21.8899 14.4991 22.0703 15H19.8966V16.5H22.2124V18H19.8966V19.5H22.2124V21H19.8966V22.5H22.2124V24.486L22.5983 24.702C23.6292 25.2796 24.435 26.1716 24.8906 27.2396C25.3463 28.3076 25.4264 29.4919 25.1185 30.6086C24.8105 31.7254 24.1318 32.7121 23.1876 33.4158C22.2434 34.1194 21.0865 34.5005 19.8966 34.5ZM23.7562 23.637V15.75C23.7562 14.7554 23.3496 13.8016 22.6258 13.0983C21.9019 12.3951 20.9202 12 19.8966 12C18.8729 12 17.8912 12.3951 17.1674 13.0983C16.4436 13.8016 16.0369 14.7554 16.0369 15.75V23.637C14.7976 24.4415 13.8575 25.6119 13.3554 26.9754C12.8532 28.3389 12.8157 29.8231 13.2484 31.2089C13.681 32.5946 14.5609 33.8085 15.758 34.6711C16.9552 35.5338 18.4061 35.9995 19.8966 35.9995C21.3871 35.9995 22.838 35.5338 24.0352 34.6711C25.2323 33.8085 26.1122 32.5946 26.5448 31.2089C26.9775 29.8231 26.9399 28.3389 26.4378 26.9754C25.9357 25.6119 24.9955 24.4415 23.7562 23.637Z" fill="#0E0E0F" />
                          <path d="M19.6992 26C20.5612 26 21.3878 26.3424 21.9973 26.9519C22.6068 27.5614 22.9492 28.388 22.9492 29.25C22.9492 30.112 22.6068 30.9386 21.9973 31.5481C21.3878 32.1576 20.5612 32.5 19.6992 32.5C18.8373 32.5 18.0106 32.1576 17.4011 31.5481C16.7916 30.9386 16.4492 30.112 16.4492 29.25C16.4492 28.388 16.7916 27.5614 17.4011 26.9519C18.0106 26.3424 18.8373 26 19.6992 26Z" stroke="#0E0E0F" />
                          <rect x="28.3945" y="21.3423" width="3" height="4" rx="0.5" stroke="#0E0E0F" />
                          <rect x="27.3945" y="24.3423" width="5" height="4" rx="0.5" fill="#0E0E0F" stroke="#0E0E0F" />
                        </g>
                        <defs>
                          <clipPath id="clip0_981_5281">
                            <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                          </clipPath>
                        </defs>
                      </svg>

                      <Toggle2 deviceId={device.deviceid} />
                      {/* <Toggle2
                      deviceId={device.deviceid}
                      onChange={(event) => handleOptionClick(event.target.value, device.deviceid)}
                      isChecked={device.isChecked}
                    /> */}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        {showPopup && (
          <SelfCleanPopup
            show={showPopup}
            deviceId={selectedDeviceId}
            onSave={handleSave}
            onClose={() => setShowPopup(false)}
          />
        )}
        {showTempLockPopup && (
          <TemperatureLockPopup
            show={showTempLockPopup}
            onSave1={handleSave}
            handletempclose={handletempclose}
          />
        )}
        {showSchedulePopup && (
          <SchedulePopup
            show={showSchedulePopup}
            handleScheduleclose={handleScheduleclose}
          />
        )}
        {showChildLockPopup && (
          <ChildLockPopup
            show={showChildLockPopup}
            handleChildcancel={handleChildcancel}
          />
        )}

      </main>
    </div>
  );
}
