import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './component/Login1';
import AssignRole from "./component/home/AssignRole";
import AssignRoles from "./component/AssignRole/AssignRoles";
import Service from './component/Service/Service';
import Controls from './component/Controls/Controls';
import Analytics from './component/AnalyticsHome/Analytics';
import Homepage from './component/home/Homepage';

// Custom hook to check authentication
const useAuth = () => {
  // You should implement your own logic to check if the user is authenticated
  // This could involve checking for a valid token in localStorage or a global state
  const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return !!token; // Returns true if token exists, false otherwise
  };

  return { isAuthenticated };
};

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    // Redirect to login if not authenticated
    return <Navigate to="/" replace />;
  }

  return children;
};

// Axios interceptor to handle 401 and 403 errors

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 401)) {
      // Clear any stored authentication data
      localStorage.removeItem('token');
      // Redirect to login page
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/Homepage"
          element={
            <ProtectedRoute>
              <Analytics />
            </ProtectedRoute>
          }
        />
        <Route
          path='/AssignRole'
          element={
            <ProtectedRoute>
              <AssignRole />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AssignRoles/:role"
          element={
            <ProtectedRoute>
              <AssignRoles />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Service'
          element={
            <ProtectedRoute>
              <Service />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Controls'
          element={
            <ProtectedRoute>
              <Controls />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Userpage'
          element={
            <ProtectedRoute>
              <Homepage />
            </ProtectedRoute>
          }
        />
        <Route
          path='/login'
          element={
            <ProtectedRoute>
              <Homepage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;