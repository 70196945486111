import React, { useState } from 'react';
import { X } from 'lucide-react';

const ChildLockPopup = ({ onSave, handleChildcancel }) => {
    const [isAdminOverride, setIsAdminOverride] = useState(false);
    const handleSave1= () =>{

        alert("This Device is not compatible for Child Lock")
        handleChildcancel()
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 w-[24.875rem] h-[27.3125rem] flex flex-col">
                <div className="flex justify-between items-center mb-10">
                    <h2 className="text-lg font-medium">Child Lock</h2>
                    <button className="text-gray-500 hover:text-gray-700" onClick={handleChildcancel}>
                        <X size={20} />
                    </button>
                </div>
                <p className="text-lg mb-8 text-center">Are you sure you want to<br />enable child lock?</p>

                <div className='flex items-center border mb-8 border-templocktext px-2 rounded-xl'>
                    <svg width="40" height="40" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 31.5C25.4558 31.5 31.5 25.4558 31.5 18C31.5 10.5442 25.4558 4.5 18 4.5C10.5442 4.5 4.5 10.5442 4.5 18C4.5 25.4558 10.5442 31.5 18 31.5Z" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18 12H18.015V12.015H18V12Z" stroke="#828282" stroke-width="2" stroke-linejoin="round" />
                        <path d="M18 18V24" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <p className="text-sm font-normal text-subtext rounded-2xl ml-4">
                        Once temperature lock is enabled, no one can go below the set temperature.
                    </p>
                </div>

                <div className="flex items-start mb-10">
                    <input
                        type="checkbox"
                        id="adminOverride"
                        className="mr-3 w-5 h-5 mt-0.5"
                        checked={isAdminOverride}
                        onChange={(e) => setIsAdminOverride(e.target.checked)}
                    />
                    <label htmlFor="adminOverride" className="text-sm">
                        Check this box to give Admin+ and Admin permission to override child lock.
                    </label>
                </div>

                <div className="flex justify-between ">
                    <button
                        onClick={handleSave1}
                        className="w-full px-8 py-2 bg-primary text-white rounded-md"
                    >
                        Yes
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChildLockPopup;